import "./Header.css";
import Logo from "./logo.webp";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import OpenSeaLogo from "./opensea-logo.svg";
import TelegramLogo from "./telegram-logo.svg";
import XLogo from "./x-logo.png";

export function Header() {
  return (
    <header className="header-container">
      <a
        href="https://www.galileoprotocol.io/"
        target="_blank"
        rel="noreferrer"
      >
        <img src={Logo} alt="Morty Logo" className="logo" />
      </a>

      <div className="header-button__container">
        <a
          href="https://twitter.com/galileoprotocol"
          target="_blank"
          rel="noreferrer"
        >
          <img src={XLogo} alt="x-logo" />
        </a>

        <a
          href="https://t.me/galileoprotocolcommunity"
          target="_blank"
          rel="noreferrer"
        >
          <img src={TelegramLogo} alt="telegram-logo" />
        </a>

        <a
          href={`${process.env.REACT_APP_OPENSEA_URL}/${process.env.REACT_APP_CONTRACT}`}
          target="_blank"
          rel="noreferrer"
        >
          <img src={OpenSeaLogo} alt="open-sea-logo" />
        </a>

        <ConnectButton chainStatus="icon" />
      </div>
    </header>
  );
}
