import { useProvider } from "wagmi";
import { useState, useEffect } from "react";
import { Contract } from "ethers";
import { contractABI } from "../utils";

export function useGetClaimStatus(address) {
  const provider = useProvider();
  const [status, setStatus] = useState(false);

  useEffect(() => {
    if (provider) {
      (async () => {
        try {
          const contract = new Contract(
            process.env.REACT_APP_CONTRACT,
            contractABI,
            provider
          );
          const _status = await contract.getMintStatus(address);
          setStatus(_status);
        } catch (error) {
          console.log("claim-status-error", error);
        }
      })();
    }
  }, [provider, address]);

  return status;
}
