import "./Footer.css";
import OpenSeaLogo from "../header/opensea-logo.svg";
import TelegramLogo from "../header/telegram-logo.svg";
import XLogo from "../header/x-logo.png";

export function Footer() {
  return (
    <footer className="footer-container">
      <p>with ❤️ from Galileo Protocol</p>

      <div className="footer-button__container">
        <a
          href="https://twitter.com/galileoprotocol"
          target="_blank"
          rel="noreferrer"
        >
          <img src={XLogo} alt="x-logo" />
        </a>

        <a
          href="https://t.me/galileoprotocolcommunity"
          target="_blank"
          rel="noreferrer"
        >
          <img src={TelegramLogo} alt="telegram-logo" />
        </a>

        <a
          href={`${process.env.REACT_APP_OPENSEA_URL}/${process.env.REACT_APP_CONTRACT}`}
          target="_blank"
          rel="noreferrer"
        >
          <img src={OpenSeaLogo} alt="open-sea-logo" />
        </a>
      </div>
    </footer>
  );
}
