import { useSigner, useProvider } from "wagmi";
import { useEffect, useState } from "react";
import { contractABI } from "../utils";
import { Contract } from "ethers";

export function useMint(address) {
  const { data: signer } = useSigner();
  const provider = useProvider();
  const [states, setStates] = useState({
    isSuccess: false,
    isLoading: false,
    isError: false,
  });

  useEffect(() => {
    if (provider && signer && address) {
      (async () => {
        try {
          setStates({ isSuccess: false, isLoading: true, isError: false });

          const _contract = new Contract(
            process.env.REACT_APP_CONTRACT,
            contractABI,
            provider
          );

          const status = await _contract.getMintStatus(address);

          if (!status) {
            const contract = new Contract(
              process.env.REACT_APP_CONTRACT,
              contractABI,
              signer
            );

            const tx = await contract.safeMint();
            const receipt = await tx.wait();

            if (receipt.status === 1) {
              setStates({ isLoading: false, isSuccess: true, isError: false });
            } else {
              setStates({ isLoading: false, isSuccess: false, isError: true });
            }
          }
        } catch (error) {
          console.log("mint-error", error);
          setStates({ isLoading: false, isSuccess: false, isError: true });
        }
      })();
    }
  }, [address, provider, signer]);

  return { ...states };
}
