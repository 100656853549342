import GalileoNft from "./galileo-nft.mp4";
import "./Modal.css";
import { useAccount } from "wagmi";
import { useGetClaimStatus, useMint, useListenTransfer } from "../../hooks";

export function Modal({ handleClose }) {
  const { address } = useAccount();
  const status = useGetClaimStatus(address);
  const { isLoading, isSuccess, isError } = useMint(address);
  const tokenId = useListenTransfer();

  return (
    <div className="modal-container" onClick={handleClose}>
      <div
        className={`modal ${isSuccess ? "success" : "failed"}`}
        onClick={(event) => event.stopPropagation()}
      >
        {status ? (
          <p>Sorry you have already claimed the NFT</p>
        ) : (
          <>
            {isLoading ? <p>Loading...</p> : null}
            {isError ? <p>Failed to claim</p> : null}

            {isSuccess ? (
              <>
                <p>
                  <strong>Merry Christmas 🎄</strong>
                </p>
                <p>Successfully claimed 🎉🎉</p>

                <video autoPlay loop playsInline muted>
                  <source type="video/mp4" src={GalileoNft} />
                  Your browser does not support this video player.
                </video>

                <a
                  target="_blank"
                  href={`${process.env.REACT_APP_OPENSEA_URL}/${process.env.REACT_APP_CONTRACT}/${tokenId}`}
                  rel="noreferrer"
                >
                  #{tokenId} View on OpenSea ↗
                </a>
              </>
            ) : null}
          </>
        )}

        {isLoading ? null : <button onClick={handleClose}>Close</button>}
      </div>
    </div>
  );
}
