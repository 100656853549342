import { useProvider } from "wagmi";
import { useState, useEffect } from "react";
import { Contract } from "ethers";
import { contractABI } from "../utils";

export function useGetStartDate() {
  const provider = useProvider();
  const [startDate, setStartDate] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (provider) {
      (async () => {
        try {
          setIsLoading(true);

          const contract = new Contract(
            process.env.REACT_APP_CONTRACT,
            contractABI,
            provider
          );
          const startDate = await contract.startDate();

          setStartDate(startDate);
          setIsLoading(false);
        } catch (error) {
          console.log("start-end-date-error", error);
          setIsLoading(false);
        }
      })();
    }
  }, [provider]);

  return { startDate, isLoading };
}
