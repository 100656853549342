import { useProvider } from "wagmi";
import { useState, useEffect } from "react";
import { Contract } from "ethers";
import { contractABI } from "../utils";

export function useGetMintCount() {
  const provider = useProvider();
  const [count, setCount] = useState();

  useEffect(() => {
    if (provider) {
      (async () => {
        try {
          const contract = new Contract(
            process.env.REACT_APP_CONTRACT,
            contractABI,
            provider
          );
          const totalSupply = await contract.totalSupply();
          setCount(Number(totalSupply));
        } catch (error) {
          console.log("mint-count-error", error);
        }
      })();
    }
  }, [provider]);

  return count;
}
