import "./Main.css";
import { Modal } from "../modal/Modal";
import { useState, useMemo, useEffect } from "react";
import { useAccount } from "wagmi";
import { useGetStartDate, useGetEndDate, useGetMintCount } from "../../hooks";

import { validateEmail } from "../../utils";
import { db } from "../../firebase/init";
import { ref, push } from "firebase/database";

export function Main() {
  const { address } = useAccount();
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const { endDate: _endDate, isLoading: loadingEndDate } = useGetEndDate();
  const { startDate: _startDate, isLoading: loadingStartDate } =
    useGetStartDate();
  const [startPeriod, setStartPeriod] = useState("");

  const startDate = useMemo(
    () => (_startDate ? new Date(Number(_startDate) * 1000) : ""),
    [_startDate]
  );

  const endDate = useMemo(
    () => (_endDate ? new Date(Number(_endDate) * 1000) : ""),
    [_endDate]
  );

  const isEnded = useMemo(() => {
    if (endDate) {
      const currentDate = new Date();
      const diffInMillSeconds = endDate - currentDate;

      if (diffInMillSeconds <= 0) {
        return true;
      }

      return false;
    }

    return false;
  }, [endDate]);

  const count = useGetMintCount();
  const isLoading = loadingEndDate || loadingStartDate;

  useEffect(() => {
    function fillStartPeriod() {
      let _startPeriod = "";

      if (startDate) {
        const currentDate = new Date();
        const diffInMillSeconds = startDate - currentDate;

        if (diffInMillSeconds <= 0) {
          _startPeriod = "";
        } else {
          const diffBtwDatesInSec = Math.floor(diffInMillSeconds / 1000);
          const diffBtwDatesInMin = Math.floor(diffBtwDatesInSec / 60);

          if (diffBtwDatesInMin < 60) {
            const minToShow =
              diffBtwDatesInMin < 10
                ? `0${diffBtwDatesInMin}`
                : diffBtwDatesInMin;

            _startPeriod = `00d:00h:${minToShow}m`;
          } else {
            const diffBtwDatesInHours = Math.floor(diffBtwDatesInMin / 60);

            if (diffBtwDatesInHours < 24) {
              const hourToShow =
                diffBtwDatesInHours < 10
                  ? `0${diffBtwDatesInHours}`
                  : diffBtwDatesInHours;

              const minToShow =
                diffBtwDatesInMin % 60 < 10
                  ? `0${diffBtwDatesInMin % 60}`
                  : diffBtwDatesInMin % 60;

              _startPeriod = `00d:${hourToShow}h:${minToShow}m`;
            } else {
              const diffBtwDays = Math.floor(diffBtwDatesInHours / 24);
              const daysToShow =
                diffBtwDays < 10 ? `0${diffBtwDays}` : diffBtwDays;

              const hours = diffBtwDatesInHours % 24;
              const hoursToShow = hours < 10 ? `0${hours}` : hours;

              const minToShow =
                diffBtwDatesInMin % 60 < 10
                  ? `0${diffBtwDatesInMin % 60}`
                  : diffBtwDatesInMin % 60;

              _startPeriod = `${daysToShow}d:${hoursToShow}h:${minToShow}m`;
            }
          }
        }
      }

      setStartPeriod(_startPeriod);
    }

    let intervalId = setInterval(fillStartPeriod, 60000);
    fillStartPeriod();

    return () => clearInterval(intervalId);
  }, [startDate]);

  const handleInputChange = (event) => {
    setError("");
    setEmail(event.target.value);
  };

  const handleClaimClick = () => {
    if (!email) {
      setError("Please enter your email address");
      return;
    }

    if (!validateEmail(email)) {
      setError("Please enter a valid email address");
      return;
    }

    try {
      push(ref(db, "users"), {
        email,
        address,
      });

      console.log("Email logged");
    } catch (error) {
      console.log("Error while logging email", error);
    }

    setShowModal(true);
  };

  return (
    <>
      {showModal ? <Modal handleClose={() => setShowModal(false)} /> : null}

      <div className="main-container">
        <h1>Claim the magic of Christmas</h1>
        <p className="main-container__subtitle">
          Wishing you joy, peace, and your exclusive Christmas Soul Bound NFTs
        </p>

        {count ? (
          <p className="main-container__greetings">
            {count} Galileo Christmas Treasures NFTs claimed so far
          </p>
        ) : null}

        {startPeriod && !isLoading ? (
          <p className="main-container__counter">
            Claim your Galileo Christmas Treasures NFTs in {startPeriod}
          </p>
        ) : null}

        {isEnded && !isLoading ? (
          <p className="main-container__counter">
            Claiming has ended for Galileo Christmas Treasures NFTs
          </p>
        ) : null}

        {isLoading ? (
          <p className="main-container__counter">Getting the status...</p>
        ) : null}

        {isEnded || startPeriod || isLoading ? null : (
          <>
            <div>
              <input
                type="email"
                value={email}
                onChange={handleInputChange}
                className="user-email__input"
                placeholder="Enter your Email address to claim the NFT"
              />
              <p className="valid-email__msg">
                Enter a valid email to be eligible for the rewards and
                additional benefits
              </p>

              {error ? <p className="error">{error}</p> : null}
            </div>

            <div className="main-container__button--container">
              {!address ? (
                <p className="main-container__info">Connect wallet to claim</p>
              ) : null}

              <button
                className="main-container__button"
                onClick={handleClaimClick}
                disabled={!address || !!startPeriod || isEnded}
              >
                Claim
                <span />
                <span />
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
}
