import { useProvider } from "wagmi";
import { contractABI } from "../utils";
import { Contract } from "ethers";
import { useEffect, useState } from "react";

export function useListenTransfer() {
  const provider = useProvider();
  const [removeListener, setRemoveListener] = useState();
  const [tokenId, setTokenId] = useState();

  useEffect(() => {
    if (provider) {
      (async () => {
        const contract = new Contract(
          process.env.REACT_APP_CONTRACT,
          contractABI,
          provider
        );

        contract.on("Transfer", (from, to, tokenId, event) => {
          setRemoveListener(event?.removeListener);
          setTokenId(Number(tokenId));
        });
      })();
    }

    return () => {
      removeListener && removeListener();
    };
  }, [provider, removeListener]);

  return tokenId;
}
